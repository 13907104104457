var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"primary mt-4 mx-0 pa-6 white--text",attrs:{"cols":"12"}},[_c('v-row',[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("commitees management")])]),_c('v-row',{},[_c('div',{},[_vm._v(" Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim eius tempore natus! Neque dolore explicabo aliquid inventore consequuntur vitae illum ratione doloribus quo, cum in, soluta perferendis laboriosam qui. ")])])],1),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("new commitee")],1)]}}])},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-title',[_c('p',{staticClass:"text-uppercase mx-auto"},[_vm._v("new commitee")])]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_c('v-form',[_c('v-text-field',{attrs:{"label":"Name","outlined":"","dense":""},model:{value:(_vm.addName),callback:function ($$v) {_vm.addName=$$v},expression:"addName"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"primary"}},[_vm._v(" create commitee ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" create joint commitee ")],1)]}}]),model:{value:(_vm.addJoint),callback:function ($$v) {_vm.addJoint=$$v},expression:"addJoint"}},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-title',[_c('p',{staticClass:"mx-auto text-uppercase"},[_vm._v("new joint commitee")])]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_c('v-autocomplete',{attrs:{"label":"Select Commitees","items":['commitee 1', 'commitee 2', 'commitee 3'],"outlined":"","dense":"","chips":"","multiple":"","counter":"2","menu-props":{ closeOnClick: true }},model:{value:(_vm.jointCommitees),callback:function ($$v) {_vm.jointCommitees=$$v},expression:"jointCommitees"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.addJoint = false}}},[_vm._v(" cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" create ")])],1)],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"text-capitalize elevation-10 mt-2",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"show-expand":"","single-expand":true,"item-key":"name"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.deleteDialog = true}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('v-list',{attrs:{"subheader":"","dense":""}},[_c('v-subheader',{staticClass:"text-uppercase black--text"},[_vm._v(" "+_vm._s(item.name)+" members ")]),_vm._l((5),function(item){return _c('v-list-item',{key:item},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-circle-small ")])],1),_c('v-list-item-title',[_vm._v(" david kimani ")])],1)})],2)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-title',[_c('p',{staticClass:"text-uppercase mx-auto"},[_vm._v("edit commitee")])]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_c('v-text-field',{attrs:{"label":"Name","outlined":"","dense":""},model:{value:(_vm.editName),callback:function ($$v) {_vm.editName=$$v},expression:"editName"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" save ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('p',{staticClass:"mx-auto text-uppercase"},[_vm._v("delete commitee")])]),_c('v-card-text',[_c('p',{staticClass:"text-center text-subtitle-1"},[_vm._v(" Are you sure you want to delete this item? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"}},[_vm._v(" delete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }